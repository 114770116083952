* {
  min-height: 0;
  min-width: 0;
}
*:focus {
  outline: #5dd5cb auto 1px;
}

body::-webkit-scrollbar {
  width: 0px;
}
.scrolling {
  max-height: 100vh;
  overflow-y: auto;
}
.scrolling::-webkit-scrollbar {
  width: 10px;
}
.scrolling::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.scrolling::-webkit-scrollbar-thumb {
  background-color: #49d0c5;
  outline: 1px solid #5dd5cb;
}
.Sidebar-box::-webkit-scrollbar {
  width: 3px;
}
.Sidebar-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
.Sidebar-box::-webkit-scrollbar-thumb {
  background-color: #49d0c5;
  outline: 0px solid #5dd5cb;
}
.App {
  display: flex;
}
.Sidebar {
  flex: 0 0 200px;
  transition: 10ms;
  position: relative;
  top: 0;
  left: 0;
  z-index: 9;
  border-right: 1px solid transparent;
}
.Menu-hide {
  margin-left: -200px;
  transition: 0.3s;
}
.Sidebar-box {
  height: 100vh;
  min-width: 200px;
  position: fixed;
  z-index: 2;
  overflow-y: auto;
  opacity: 1 !important;
  padding-top: 70px;
  box-sizing: border-box;
}
.Sidebar-logo {
  height: 50px;
  border-bottom: 1px solid transparent;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  transition: 10ms;
  background: #fff;
}
.Sidebar-logo a{
  line-height: 1;
}
.Sidebar-logo-hide {
  left: -200px;
  transition: 0.3s;
}
.Logos {
  height: 35px;
}
.Sidebar-menu-icon {
  position: fixed;
  left: 210px;
  top: 15px;
  z-index: 99;
  transition: 0.3s;
  font-size: 22px;
  color: #fff;
}
.Sidebar-menu-icon-move {
  left: 10px;
}
.Sidebar-button {
  cursor: pointer;
  background-color: transparent;
  margin-top: 0;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
}
.Sidebar-button-title {
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 3;
  text-transform: uppercase;
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-icon::before {
  margin-left: 0;
  font-size: 14px;
}
.Sidebar-lists {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.Sidebar-list {
  margin-top: 5px;
}
.Sidebar-link {
  display: inline-block;
  margin-top: 5px;
  border-bottom: 1px solid transparent;
  transition: border 0.2s ease 0s;
  padding: 0 15px;
  text-decoration: none;
}
.Sidebar-link:hover {
  color: rgb(109, 109, 109);
}
.Sidebar-title-link {
  text-decoration: none;
}
.Juz-link {
  display: block;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.Home-video {
  margin-top: 0;
  position: fixed;
  width: 100px;
  right: 0;
  bottom: 0;
}
.Video-ovarley {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 9999999;
}
.Main {
  flex: 1 1 auto;
}
.Header {
  height: 50px;
  display: flex;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 15px;
  position: sticky;
  z-index: 1;
  width: 100%;
  top: 0;
  right: 0;
  box-sizing: border-box;
}
.Header-lists {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.Header-link {
  padding: 0 10px;
  text-decoration: none;
  color: #333;
}
.Header-link:hover {
  color: rgb(121, 111, 111);
}
.Header-link-icon {
  font-size: 20px;
  color: #fff;
}
.Navbar-logo {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
  height: 40px;
  width: 220px;
  position: relative;
  top: 2px;
}
.Language {
  border: navajowhite;
  background-image: none;
  box-shadow: none;
  color: #fff;
  font-size: 16px;
  margin-right: 10px;
}
.Content {
  min-height: calc(100vh - 50px);
  box-sizing: border-box;
  position: relative;
}
.Content > div {
  min-height: calc(100vh - 80px);
  display: flex;
}
.Sun {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
}
.Moon {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
}
/****** Home page ******/
.Home {
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
}
.Home-banner-wrapper {
  width: 100%;
  height: 450px;
  padding: 15px;
  box-sizing: border-box;
}
.Home-banner {
  width: 60%;
  margin: 35px auto 60px auto;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  flex-direction: column;
  text-transform: uppercase;
  position: relative;
}
.Banner-logos {
  height: 95px;
}
.Banner-title {
  font-size: 1.7rem;
  color: #000;
}
.Home-banner h1 {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(221, 221, 221, 0.5);
}
.Search {
  width: 60%;
  margin: 0 auto;
}
.Search form {
  display: flex;
  width: 100%;
  margin: 0 auto;
}
.Search input[type="text"] {
  padding: 6px;
  font-size: 18px;
  border: none;
  height: 50px;
  box-sizing: border-box;
  border-radius: 6px 0 0 6px;
  flex: 1;
  color: #333;
}
.Search button {
  padding: 3px;
  background: #fff;
  font-size: 18px;
  border: none;
  cursor: pointer;
  height: 50px;
  box-sizing: border-box;
  border-radius: 0 6px 6px 0;
}
.Search button:hover {
  background: #f2f2f2;
}
.Search-icon {
  font-size: 25px;
  height: 100%;
  padding: 0 10px;
  border-radius: 6px;
}
.Search-wrapper-parent {
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
}
.Search-wrapper {
  width: 60%;
  margin: -55px auto 0 auto;
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  min-height: 200px;
}
.Search-box {
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  box-sizing: border-box;
}
.Search-info {
  padding: 20px 15px;
  box-sizing: border-box;
  border-radius: 6px;
}
.Search-items {
  border-bottom: 1px solid transparent;
  padding: 10px 0 10px 40px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  text-decoration: none;
}
.Search-items h4:nth-child(1) {
  text-align: right;
}
.Search-items h4 {
  font-size: 18px;
  cursor: pointer;
  margin: 10px 0;
  padding: 0;
}
.Search-items > div {
  position: absolute;
  top: 50%;
  left: 0px;
}
.Search-text {
  font-weight: 500;
  margin: 20px 0;
  font-size: 1rem;
}
.Search-quick-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 75px;
}
.Search-quick-list li {
  min-width: 28%;
}
.Quick-links {
  text-decoration: none;
  font-weight: 500;
}
.suhuf-box {
  display: flex;
  justify-content: center;
}
.Suhufi {
  align-self: center;
  width: 100%;
  min-width: 100%;
  height: 100%;
}
.Slider-container {
  width: 55%;
  text-align: center;
  display: block;
  margin: 0 auto;
  transition: 0.2s;
}
.Slider-wrapper {
  background: url("/images/cover-white.png");
  background-repeat: no-repeat;
  background-size: 62% 100%;
  background-position: top;
  height: 100%;
}
.Slider-wrapper.dark {
  background: url("/images/cover-black.png");
  background-repeat: no-repeat;
  background-size: 62% 100%;
  background-position: top;
  height: 100%;
}
.slick-slider {
  width: 100%;
  margin-left: 1px;
}
.slick-slider .slick-list {
  padding-top: 0;
}
.slick-track {
  display: flex !important;
}
.slick-track .slick-slide {
  height: auto;
  display: flex !important;
}
.slick-track .slick-slide > div {
  display: flex !important;
}

.Slider-translation {
  width: 55%;
  text-align: left;
  display: block;
  margin: 10px auto;
}
.Slider-translation .slick-slider {
  width: 100%;
  margin-left: 1px;
}
.Slider-translation-arabic {
  width: 55%;
  text-align: left;
  display: block;
  margin: 10px auto;
}
.Slider-translation-arabic .slick-slider {
  width: 100%;
  margin-left: 1px;
}
.page-image {
  width: 100%;
  height: 100%;
  margin-top: 40px;
}
.Slider-translation-arabic .page-image {
  width: 90%;
  height: 100%;
  margin-top: 25px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.Translate-text-box {
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 5px;
}
.slick-active .Translate-text-box {
  padding-left: 20px;
}
.slick-active.slick-current .Translate-text-box {
  padding-left: 0;
}
.Text-left {
  line-height: 22px;
  font-size: 16px;
  margin-bottom: 5px;
}
.slick-slide {
  position: relative;
}
.slick-active > div {
  padding: 12px 12px 12px 0;
  box-sizing: border-box;
}
.slick-current > div {
  padding: 12px 0 12px 12px;
  box-sizing: border-box;
  width: 100%;
}
.Slider-translation-arabic .slick-current > div {
  padding: 12px;
  box-sizing: border-box;
}
.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  background: transparent;
}
.slick-arrow.slick-next {
  right: -45px;
}
.slick-arrow.slick-prev {
  left: -45px;
}
.slick-arrow.slick-prev:before,
.slick-arrow.slick-next:before {
  font-size: 40px;
  color: #f2f2f2;
}
.Page-no {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 35px;
  display: flex;
  padding: 0 30px;
  box-sizing: border-box;
}
.Page-no > span {
  flex: 1;
}
.Page-no > span:nth-child(1) {
  text-align: left;
}
.Page-no > span:nth-child(2) {
  text-align: center;
}
.Page-no > span:nth-child(3) {
  text-align: right;
}
.Translate-slider-item .Page-no {
  left: 0;
  top: 24px;
  padding: 0 20px 0 25px;
}
.Translate-arabic-slider-item .Page-no {
  left: 0;
  top: 25px;
  padding: 0 20px;
}
.Custom-img-box {
  overflow: hidden;
}
.Mode-links {
  display: flex;
  position: fixed;
  top: 10px;
  left: calc(50% - 55px);
  z-index: 999;
  color: #fff;
  cursor: pointer;
  transition: 0.2s;
}
.Sidebar-show {
  left: calc(50% + 40px);
}
.Mode-link {
  padding: 10px;
  font-size: 20px;
}
.Audio-ayat {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99;
  border-radius: 20px;
  transition: 0.3s;
}
.Player-controls {
  display: flex;
  align-items: flex-end;
}
.Audio-player {
  height: 54px;
  width: 250px;
  background: #f1f3f4;
  border-radius: 20px;
}
.Audio-open-button {
  background: #f2f2f2;
  height: 54px;
  width: 105px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  border-radius: 0 20px 20px 0;
  margin-left: -20px;
  padding-right: 10px;
}
.Audio-controls {
  width: 335px;
  height: 140px;
  position: relative;
  z-index: 9;
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 5px;
}
.Open-button {
  font-size: 20px;
  color: #000;
}
.Close-audio {
  background: #f1f3f4;
  padding: 10px;
  border-radius: 50px;
  position: relative;
  left: 10px;
  bottom: -5px;
}
.Close-class {
  bottom: -40px !important;
}
.Prev-icon {
  padding: 7px;
}
.Next-icon {
  padding: 7px;
}
.Repeat-icon {
  padding: 5px;
}
.Select-sura option:hover {
  background: #49d0c5;
}
div.disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #ccc;
}
.Controls-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
  font-size: 14px;
}
.Controls-item:last-child {
  border-bottom: none;
}
.Select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Select-sura-item {
  flex: 2;
}
.Select-ayah-item {
  flex: 1;
}
.Select-sura {
  width: 130px;
  margin-left: 5px;
  border-color: #49d0c5;
  color: #333;
}
.Select-ayah {
  width: 50px;
  margin-left: 5px;
  border-color: #49d0c5;
  color: #333;
}
.Repeat-box {
  display: flex;
}
.Repeat-box label {
  margin-right: 10px;
}
.Repeat-box input {
  border-color: #49d0c5;
  color: #49d0c5;
}
.Repeat-box input:checked {
  background-color: red;
}
.Start-button button {
  width: 100px;
  border: none;
  margin-top: 30px;
  background: #49d0c5;
  color: #fff;
  padding: 8px;
}
/******* settings page ******/
.Suhuf-settings {
  display: flex;
  width: 100%;
  min-width: 100%;
  flex-direction: column;
}
.Suhuf-settings-box {
  display: flex;
}
.Settings-page {
  display: flex;
  width: 90%;
  margin: 0 auto;
}
.Settings-page > div {
  width: 50%;
  padding: 0 10px;
  box-sizing: border-box;
}
.Settings-banner-header {
  justify-content: center !important;
  padding: 30px !important;
}
.Translation-lists {
  padding: 10px 5px;
  border-bottom: 1px solid transparent;
  margin-right: 20px;
  cursor: pointer;
}
.Translation-list-1 {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 5px;
}
.Translation-list-2 {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.Translate-slider-item {
  min-height: 80vh;
  display: flex !important;
  flex-direction: column;
  font-size: 14px;
  text-align: left;
  padding: 30px 10px 10px 10px;
  box-sizing: border-box;
  line-height: 1.3;
}
.Translate-arabic-slider-item {
  display: flex !important;
  flex-direction: column;
  font-size: 14px;
  text-align: left;
  padding: 5px 0 10px 0;
  box-sizing: border-box;
  line-height: 1.3;
}
.TranslateArabic-page {
  display: flex;
}
.TranslateArabic-page > div:nth-child(1) {
  padding-top: 25px;
  padding-right: 10px;
}
.TranslateArabic-page > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.Bg-green {
  background-color: #5dd5cb !important;
  color: #fff !important;
}
.Bg-white {
  background-color: #fff;
  color: #000;
}
.C-green {
  color: #5dd5cb;
}
.C-white {
}
.Bg-green:hover {
  background: #5dd5cb;
}
.Bg-white:hover {
  background: #f2f2f2;
}
.Downloading-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0.5;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Downloading-element {
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50% - 100px);
  color: #000;
  z-index: 3;
}
.Translation-arrow {
  display: none;
  transition: 0.2s;
  margin-left: 20px;
  position: relative;
  top: 3px;
}
/****** duas style *******/
.Duas {
  width: 100%;
  min-width: 100%;
  box-sizing: border-box;
}
.Banner-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.Duas-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.Card {
  width: 48%;
  padding: 15px;
  box-shadow: 0px 0px 5px 5px rgba(221, 221, 221, 0.5);
  box-sizing: border-box;
  margin-bottom: 30px;
}
.Card:nth-child(odd) {
  margin-right: 30px;
}
.Ayat-text {
  font-size: 20px;
  line-height: 30px;
}
/****** mobile apps ********/
.Mobile-apps {
  display: flex;
  width: 100%;
  min-width: 100%;
}
.Duas-app,
.Quran-app {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
}
.Iphone-app {
  width: 250px;
}
.Android-app {
  width: 220px;
}
.Download-ios img {
  width: 30px;
}
.Download-android img {
  width: 30px;
}
.Donwload-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
}
.Download-android,
.Download-ios {
  display: flex;
  width: 165px;
  height: 60px;
  justify-content: space-evenly;
  background: #49d0c5;
  align-items: center;
  color: #fff;
  color: #fff;
  text-decoration: none;
}
.Download-android:hover,
.Download-ios:hover {
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.3);
}
.Download-title {
  display: flex;
  flex-direction: column;
}
.qr-code {
  width: 420px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.qr-code-img {
  width: 200px;
  height: 200px;
}
.Apps-title {
  font-size: 1.5rem;
}
.screens {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.screens-img {
  width: 200px;
  height: 400px;
  border-radius: 30px;
}
/*** animation for pages *****/
.fade-enter {
  transition: opacity 200ms ease-in;
  opacity: 0;
  z-index: 1;
}
.fade-exit {
  transition: opacity 0ms ease-in;
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
}
.slide-enter,
.slide-exit {
  transition: transform 400ms ease-out;
}
.slide-enter {
  transform: translateX(100%);
}
.slide-enter.slide-enter-active {
  transform: translateX(0%);
}
.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}
.slide-exit-active {
  transform: translateX(-100%);
}
/****** privacy policy *******/
.Privacy-policy {
  width: 60%;
  margin: 20px auto;
}
.Sound-icon {
  position: relative;
}
.Sound-icon span {
  display: block;
  bottom: 0px;
  width: 9px;
  height: 5px;
  background: #fff;
  position: absolute;
  animation: audio-wave 1s infinite ease-in-out;
}
.Sound-icon span:nth-child(2) {
  left: 11px;
  animation-delay: 0.3s;
}
.Sound-icon span:nth-child(3) {
  left: 22px;
  animation-delay: 0.6s;
}
.Sound-icon-mute {
  position: relative;
}
.Sound-icon-mute span {
  display: block;
  bottom: 0px;
  width: 9px;
  height: 5px;
  background: #fff;
  position: absolute;
}
.Sound-icon-mute span:nth-child(2) {
  left: 11px;
  height: 10px;
  transform: translateY(2.5px);
}
.Sound-icon-mute span:nth-child(3) {
  left: 22px;
  height: 15px;
  transform: translateY(5px);
}
.Home-sound {
  position: absolute;
  right: 40px;
  top: 10px;
}
.Home-sound > div {
  display: flex;
  align-items: center;
}
.Volume-icon {
  position: relative;
  top: -3px;
  margin-right: 5px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
@keyframes audio-wave {
  0% {
    height: 5px;
    transform: translateY(0px);
    background: #fff;
  }
  25% {
    height: 14px;
    transform: translateY(5px);
    background: #fff;
  }
  /*effect is to animate the height of each span from 5px to 30px*/
  /*translateY makes Y axis move down to give the effect that it is growing from the center*/

  50% {
    height: 5px;
    transform: translateY(0px);
    background: #fff;
  }
  100% {
    height: 5px;
    transform: translateY(0px);
    background: #fff;
  }
}

@media (max-width: 1690px) {
  .Slider-container {
    width: 65%;
  }
  .Slider-wrapper {
    background-size: 68% 100%;
  }
  .Slider-wrapper.dark {
    background-size: 68% 100%;
  }
  .Slider-translation {
    width: 65%;
  }
  .Slider-translation-arabic {
    width: 65%;
  }
  .Translate-text-box {
    line-height: 20px;
    font-size: 16px;
  }
  .Text-left {
    line-height: 20px;
    font-size: 16px;
  }
}

@media (max-width: 1450px) {
  .Translate-text-box {
    line-height: 20px;
    font-size: 16px;
  }
  .Text-left {
    line-height: 20px;
    font-size: 14px;
  }
}

@media (max-width: 1400px) {
  .Slider-translation {
    width: 65%;
  }
  .Slider-translation-arabic {
    width: 65%;
  }
  .Translate-text-box {
    line-height: 18px;
    font-size: 14px;
  }
  .Text-left {
    line-height: 16px;
    font-size: 14px;
  }
  .Home-banner,
  .Search-wrapper {
    width: 90%;
  }
}

@media (max-width: 1300px) {
  .Slider-container {
    width: 65%;
    margin-top: 10px;
  }
  .Slider-wrapper {
    background-size: 74% 100%;
  }
  .Slider-wrapper.dark {
    background-size: 74% 100%;
  }
  .Slider-translation {
    width: 70%;
    margin-top: 10px;
  }
  .Slider-translation-arabic {
    width: 70%;
    margin-top: 10px;
  }
  .Translate-text-box {
    line-height: 18px;
    font-size: 14px;
  }
  .Page-no {
    top: 35px;
  }
  .page-image {
    margin-top: 35px;
  }
  .Banner-logos {
    height: 75px;
  }
  .Home-banner-wrapper {
    height: 405px;
  }
  .Home-banner {
    height: 275px;
  }
}

@media (max-width: 1201px) {
  .Sidebar {
    position: absolute;
    height: 100vh;
    width: 200px;
  }
}
@media (max-width: 1100px) {
  .Home-banner,
  .Search-wrapper {
    width: 90%;
  }
  .scrolling::-webkit-scrollbar {
    width: 0;
  }
  .Sidebar-menu-icon {
    left: 10px;
  }
  .Card:nth-child(odd) {
    margin-right: 15px;
  }
  .Card {
    margin-bottom: 20px;
  }
  .Slider-container {
    width: 75%;
  }
  .Slider-translation {
    width: 75%;
  }
  .slick-current > div > div {
    box-shadow: none;
  }
  .Navbar-logo {
    width: auto;
  }
  .Sidebar-show {
    left: calc(50% - 28px);
  }
  .arabic-translate {
    display: none;
  }
  .Page-no {
    top: 5px;
  }
  .Slider-wrapper {
    background-image: none;
  }
  .Slider-wrapper.dark {
    background-image: none;
  }
  .slick-active .Translate-text-box {
    padding-left: 0;
  }
  .Language {
    border: none;
    background-image: none !important;
    box-shadow: none;
    margin-right: 0px;
    text-shadow: none;
    width: 80px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .Language::-ms-expand {
    display: none;
  }
  .Translate-slider-item .Page-no {
    left: 0;
    top: 10px;
    padding: 0 10px;
  }
}

@media (max-width: 993px) {
  .Mobile-apps {
    flex-direction: column;
  }
}
@media (max-width: 567px) {
  .slick-slider {
    width: 100%;
  }
  .Slider-container {
    width: 95%;
  }
  .Slider-translation {
    width: 95%;
  }
  .slick-current > div > div {
    box-shadow: none;
  }
  .slick-current > div {
    padding: 0;
  }
  .slick-arrow.slick-prev {
    left: -25px;
  }
  .slick-arrow.slick-next {
    right: -25px;
  }
  .Sidebar {
    z-index: 999999;
  }
  .Navbar-logo {
    display: none;
  }
  .Home-banner h1 {
    font-size: 1rem;
  }
  .Banner-logos {
    height: 58px;
  }
  .Home-banner {
    width: 100%;
    margin: 15px auto 60px auto;
    height: 200px;
  }
  .Search-wrapper {
    width: 100%;
    margin: -175px auto 0 auto;
  }
  .Search {
    width: 90%;
  }
  .Search input[type="text"] {
    font-size: 14px;
    height: 40px;
  }
  .Search button {
    height: 40px;
  }
  .Search-icon {
    font-size: 18px;
  }
  .Search-quick-list {
    flex-wrap: nowrap;
    height: 75px;
    flex-direction: column;
    height: 200px;
    justify-content: space-between;
  }
  .Search-text {
    font: normal 16px/22px Roboto !important;
  }
  .Sidebar-menu-icon-back {
    color: #a7a7a7;
  }
  .Settings-page {
    width: 95%;
    flex-direction: column;
  }
  .Settings-page > div {
    width: 100%;
  }
  .Suhuf-settings.scrolling {
    max-height: fit-content !important;
  }
  .Settings-banner-header {
    justify-content: left !important;
    padding: 20px 20px 0 20px !important;
  }
  .Banner-title {
    font-size: 1rem;
  }
  .Translation-box h3,
  .Audio-box h3 {
    font-size: 1rem;
  }
  .Translation-list-1 {
    font-size: 14px;
  }
  .Translation-list-2 {
    font-size: 12px;
  }
  .Translation-lists {
    margin-right: 0;
    display: none;
  }
  .Translation-arrow {
    display: inline-flex;
  }
  .Translation-arrow.active {
    transform: rotate(180deg);
  }
  .active-list {
    display: block;
  }
  .Mobile-apps {
    flex-direction: column;
    padding: 0 10px 30px 0;
    box-sizing: border-box;
  }
  .qr-code {
    width: 300px;
  }
  .qr-code-img {
    width: 140px;
    height: 140px;
  }
  .Donwload-wrapper {
    padding: 0 7px;
  }
  .Download-android,
  .Download-ios {
    width: 125px;
  }
  .Download-title {
    font-size: 12px;
  }
  .Download-title > span:nth-child(2) {
    font-size: 14px;
    font-weight: bold;
  }
  .Download-ios img,
  .Download-android img {
    width: 25px;
  }
  .Card {
    width: 100%;
    margin: 8px 15px;
  }
  .Mode-links {
    left: 40px;
    top: 6px;
  }
  .Home {
    padding-bottom: 80px;
  }
  .Audio-ayat {
    bottom: 40px;
  }
  .Close-class {
    bottom: -10px !important;
  }
  .screens-img {
    width: 150px;
    height: 300px;
  }
}
@media (max-width: 380px) {
  .Slider-container {
    width: 90%;
  }
  .Slider-translation {
    width: 90%;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @media {
    .Audio-player {
      background: #393939;
      margin-top: -10px;
    }
    .Audio-open-button {
      background: #393939;
    }
    .Open-button,
    .Prev-icon,
    .Next-icon {
      color: #fff;
    }
  }
}
